$.fn.timedropdown = function (opts) {

  if (opts === undefined) {
    opts = {};
  }

  /**
   * @type {Array}
   */
  const availableRanges = opts.availableRanges || [];

  /**
   * @type {number}
   */
  const interval = opts.interval || 1;

  /**
   * @type {boolean}
   */
  const ampm = !!opts.ampm;

  const _this = this;

  if (!availableRanges && availableRanges.length < 1) {
    return;
  }

  let time = [];

  const availableRangesSort = availableRanges.sort((a, b) => a.startTime[0] - b.startTime[0]);

  for (const availableRange of availableRangesSort) {
    const startHour = availableRange.startTime[0];
    const startMinute = availableRange.startTime[1];
    const endHour = availableRange.endTime[0];
    const endMinute = availableRange.endTime[1];

    const startTime = new Date();
    startTime.setHours(startHour, startMinute, 0, 0);

    const endTime = new Date();
    endTime.setHours(endHour, endMinute, 0, 0);

    while (startTime < endTime) {
      time.push(getFormattedTime(startTime.getHours(), startTime.getMinutes()));
      startTime.setMinutes(startTime.getMinutes() + interval);
    }

  }

  this.html('');
  this.data('timepicker', this.val());

  for (let timeEntry of time) {

    const option = $('<option>', {value: timeEntry});
    option.text(timeEntry);
    this.append(option);
  }

  this.on('change', function () {
    _this.trigger('changeTime', _this.val());
  });

  function getFormattedTime(hour, minute) {
    let H = '';
    let i;
    let a;

    function formatTimeUnit(timeUnit) {
      if (timeUnit < 10) {
        return '0' + timeUnit;
      }
      return timeUnit;
    }

    if (ampm) {
      a = (hour < 12) ? 'AM' : 'PM';
      H = hour % 12 || 12;
    } else {
      H = hour;
    }

    H = formatTimeUnit(H);
    i = formatTimeUnit(minute);

    return ampm ? `${H}:${i} ${a}` : `${H}:${i}`;
  }

};