/**
 *
 * jQuery Events:
 * – onEventsInit
 * – onEventsInited
 * – onEventsSubmit
 * – onEventsEdit
 *
 */

const TIME_INTERVAL = 10;

function SchedulerEvents(options = {}) {
  const schedulerControllerUrl = "/plugins/shows/scheduler-controller.jsp";
  const calendarControllerUrl = "/plugins/shows/calendar-controller.jsp";
  const appointmentControllerUrl = "/plugins/shows/appointment-controller.jsp";
  const goldSowsControllerUrl = Config.exec + "/postback/gold_show.jsp";

  const _this = this;

  const dateFormat = options.dateFormat || 'mm/dd/yy';
  const useCalendarTime = options.useCalendarTime !== false;
  const isDebug = options.debug;
  const datepickerId = '#datepicker-scheduler';
  const datetimeId = '#event-calendar-scheduler';
  const isSchedulerPage = $('input[name=isSchedulerPage]').val() === 'true';
  let performerId = 0;


  this.initDatetimepicker = function (_performerId) {
    const datepicker = $(datepickerId);
    performerId = _performerId;

    let timeObj = {};
    let schedulerTimeObj = {};
    let eventsTimeObj = {};

    if (useCalendarTime) {
      $('#time-picker').prop('disabled', true);
      $('#duration-field select').prop('disabled', true);
    }

    const calendarReq = {
      cmd: 'get-calendar',
      performerId: performerId
    };
    const schedulerReq = {
      cmd: 'get-timelock',
      performerId: performerId
    };

    const request1 = $.post(calendarControllerUrl, calendarReq, null, 'json');
    const request2 = $.post(schedulerControllerUrl, schedulerReq, null, 'json');

    if (isSchedulerPage) {
      $.when(request1, request2)
        .done((_calendarRes, _schedulerRes) => {
          const calendarRes = getResponse(_calendarRes);
          const schedulerRes = getResponse(_schedulerRes);

          timeObj = parseCalendarTime(calendarRes, true);
          schedulerTimeObj = parseCalendarTime(schedulerRes);

          showCalendarWithAvailableDates(datepicker, dateFormat, timeObj, schedulerTimeObj);
        });
    } else {
      const appointmentReq = {
        cmd: 'get-events',
        performerId: performerId
      };
      const goldSowsReq = {
        command: 'get-approved-shows-by-performer-id',
        performerId: performerId
      };

      const request3 = $.post(appointmentControllerUrl, appointmentReq, null, 'json');
      const request4 = $.get(goldSowsControllerUrl, goldSowsReq, null, 'json');

      $.when(request1, request2, request3, request4)
        .done((_calendarRes, _schedulerRes, _appointmentRes, _goldShowsRes) => {
          const calendarRes = getResponse(_calendarRes);
          const schedulerRes = getResponse(_schedulerRes);
          const appointmentRes = getResponse(_appointmentRes);
          const goldShowsRes = _goldShowsRes[0].res === true ? JSON.parse(_goldShowsRes[0].custom.result) : [];

          timeObj = parseCalendarTime(calendarRes, true);
          schedulerTimeObj = parseCalendarTime(schedulerRes);
          eventsTimeObj = parseEventsTime([...appointmentRes, ...goldShowsRes]);

          showCalendarWithAvailableDates(datepicker, dateFormat, timeObj, schedulerTimeObj, eventsTimeObj);
        });
    }

    function getResponse(response) {
      const {message, code} = response[0];
      return code !== 'ERR' ? JSON.parse(message) : [];
    }
    function parseCalendarTime(calendarArray, isCalendar) {
      const model = {};

      if (calendarArray.length === 0 && isCalendar) {
        const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

        for (const item of daysOfWeek) {
          for (let i = 0; i < 24; i++) {
            const newTime = {
              startHour: {hour: i, minute: 0},
              endHour: {hour: i, minute: 0}
            };

            if (model.hasOwnProperty(item)) {
              model[item].push(newTime);
            } else {
              model[item] = [newTime];
            }
          }
        }
      }

      for (const item of calendarArray) {
        if (item === null) {
          continue;
        }

        const newTime = {
          startHour: item['startLocalTime'],
          endHour: item['endLocalTime']
        };

        if (item.hasOwnProperty("dayOfWeek")) {
          if (model.hasOwnProperty(item.dayOfWeek)) {
            model[item.dayOfWeek].push(newTime);
          } else {
            model[item.dayOfWeek] = [newTime];
          }
        }
        else if (item.hasOwnProperty("localDate")) {
          const dateTimestamp = new Date(item.localDate.year, item.localDate.month - 1, item.localDate.day).getTime();
          newTime.id = item.id;
          if (model.hasOwnProperty(dateTimestamp)) {
            model[dateTimestamp].push(newTime);
          } else {
            model[dateTimestamp] = [newTime];
          }
        }

      }

      return model;
    }

    function parseEventsTime(events) {
      const model = {};

      for (const event of events) {
        if (event === null) {
          continue;
        }

        let startTimestamp;
        let endTimestamp;
        let startDayTimestamp;
        let endDayTimestamp;

        if (event.type === "APPOINTMENT") {
          startTimestamp = event.startTime.seconds * 1000;
          endTimestamp = event.endTime.seconds * 1000;
        } else {
          const startDate = event.startDate.date;
          const startTime = event.startDate.time;

          startTimestamp = new Date(startDate.year, startDate.month - 1, startDate.day, startTime.hour, startTime.minute, startTime.second).getTime();
          endTimestamp = startTimestamp + event.duration * 60 * 1000;
        }

        startDayTimestamp = new Date(startTimestamp);
        startDayTimestamp.setHours(0, 0, 0, 0);
        startDayTimestamp = startDayTimestamp.getTime();

        endDayTimestamp = startDayTimestamp + 24 * 60 * 60 * 1000;

        const newTime = {
          startTime: startTimestamp,
          endTime: endTimestamp
        };

        if (model.hasOwnProperty(startDayTimestamp)) {
          model[startDayTimestamp].push(newTime);
        } else {
          model[startDayTimestamp] = [newTime];
        }

        if (new Date(startTimestamp).getDate() !== new Date(endTimestamp).getDate()) {
          if (model.hasOwnProperty(endDayTimestamp)) {
            model[endDayTimestamp].push(newTime);
          } else {
            model[endDayTimestamp] = [newTime];
          }
        }

      }
      return model;
    }
  };

  function showCalendarWithAvailableDates(datepicker, dateFormat, timeObj, schedulerTimeObj, eventsTimeObj = {}) {

    const today = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => I18n.t(day));
    const allowedDaysOfWeek = Object.keys(timeObj);

    datepicker.datepicker('destroy');
    datepicker.datepicker({
      inline: true,
      altField: datetimeId,
      altFormat: dateFormat,
      dateFormat: dateFormat,
      defaultDate: today,
      minDate: today,
      maxDate: '+6m',
      beforeShowDay: function (date) {
        const day = date.getDay();
        const dayName = days[day].toUpperCase();
        const dateTimestamp = new Date(date).getTime();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let result = [];

        if (useCalendarTime) {
          if (allowedDaysOfWeek.length === 0) {
            result = [true];
          } else {
            result = [allowedDaysOfWeek.includes(dayName)];
          }
        } else {
          result = [true];
        }

        if (date.getTime() < today.getTime()) {
          result.push('ui-state-past-day');
        }

        if (timeObj[dayName] && schedulerTimeObj[dateTimestamp]) {
          const isTimeAvailable = !timeObj[dayName].every(item1 => {
            return schedulerTimeObj[dateTimestamp].some(item2 => item2.startHour.hour === item1.startHour.hour);
          });

          if (!isTimeAvailable) {
            result.push('ui-state-date-disabled');
          }
        }

        return result;
      },
      onSelect: async function () {
        const date = datepicker.datepicker('getDate');
        const day = date.getDay();
        const dayName = days[day].toUpperCase();
        const dateTimestamp = new Date(date).getTime();

        let availableTimeArray = [];
        let currentDateSchedulerTime = [];
        let currentDateEventsTime = [];

        if (timeObj.hasOwnProperty(dayName)) availableTimeArray = timeObj[dayName];
        if (schedulerTimeObj.hasOwnProperty(dateTimestamp)) currentDateSchedulerTime = schedulerTimeObj[dateTimestamp];
        if (eventsTimeObj.hasOwnProperty(dateTimestamp)) currentDateEventsTime = eventsTimeObj[dateTimestamp];
        if (useCalendarTime) {
          $('#time-picker').prop('disabled', false);
          $('#duration-field select').prop('disabled', false);
        }

        showTimepickerWithAvailableTime($('#time-picker-scheduler'), date, availableTimeArray, currentDateSchedulerTime, currentDateEventsTime);
      }
    });

    $(datepickerId+' .ui-datepicker-current-day').click();
  }

  function showTimepickerWithAvailableTime(timepickerInput, date, _availableTimeArray, _currentDateSchedulerTime, _currentDateEventsTime) {
    const availableTimeArray = _availableTimeArray.sort((a, b) => a.startHour.hour - b.startHour.hour);
    const currentDateSchedulerTime = _currentDateSchedulerTime.sort((a, b) => a.startTime - b.startTime);
    const currentDateEventsTime = _currentDateEventsTime.sort((a, b) => a.startTime - b.startTime);

    const availableTimeRangesForSelectedDay = function() {
      let utr = [];
      if (!availableTimeArray || availableTimeArray.length === 0 || !useCalendarTime) {
        return utr;
      }

      for (const entry of availableTimeArray) {
        let startHour = entry.startHour.hour;
        let startMinute = entry.startHour.minute;
        let endHour = entry.endHour.hour;
        let endMinute = entry.endHour.minute;

        const allIntervals = [];
        const availableIntervals = [];

        let skip = false;

        if (startHour === endHour && startMinute === endMinute) {
          endHour++;
        }

        if (!isSchedulerPage) {
          for (const schedulerTime of currentDateSchedulerTime) {
            let schedulerStartHour = schedulerTime.startHour.hour;

            if(schedulerStartHour === startHour) {
              skip = true;
            }

          }
          
          if (skip) continue;
        }

        for (let i = 0; i < 60; i += TIME_INTERVAL) {
          const startIntervalTimestamp = new Date(date).setHours(startHour, i, 0, 0);
          const endIntervalTimestamp = new Date(date).setHours(startHour, i + TIME_INTERVAL, 0, 0);
          allIntervals.push([i, i + TIME_INTERVAL])

          let intervalSkip = false;

          for (const eventTime of currentDateEventsTime) {
            const startEventTimestamp = eventTime.startTime;
            const endEventTimestamp = eventTime.endTime + 60 * 1000;

            if (
              (startEventTimestamp >= startIntervalTimestamp && startEventTimestamp < endIntervalTimestamp) ||
              (endEventTimestamp >= startIntervalTimestamp && endEventTimestamp < endIntervalTimestamp) ||
              (startEventTimestamp <= startIntervalTimestamp && endEventTimestamp >= startIntervalTimestamp)
            ) {
              intervalSkip = true;
              continue;
            }
          }

          if (!intervalSkip) availableIntervals.push([i, i + TIME_INTERVAL])
        }

        if (availableIntervals.length === 0) continue;
        else if (availableIntervals.length >= 1 && availableIntervals.length < allIntervals.length) {
          const mergedIntervals = [availableIntervals[0]];

          for (const interval of availableIntervals) {
            const lastMergedInterval = mergedIntervals[mergedIntervals.length - 1];

            if (interval[0] <= lastMergedInterval[1]) {
              lastMergedInterval[1] = Math.max(lastMergedInterval[1], interval[1]);
            } else {
              mergedIntervals.push(interval);
            }
          }

          for (const mergedInterval of mergedIntervals) {
            startMinute = mergedInterval[0];
            endMinute = mergedInterval[1];

            if (endMinute < 60) endHour = endHour - 1;
            if (endMinute >= 60) endMinute = 0;

            pushUtr();
          }

          continue;
        }
        else if (availableIntervals.length === allIntervals.length) pushUtr();

        function pushUtr() {
          isTimeAdded = true;
          utr.push({
            startTime: [startHour, startMinute],
            endTime: [endHour, endMinute]
          });
        }
      }

      return utr;
    }();

    if (availableTimeRangesForSelectedDay.length > 0) {
      timepickerInput.renderDayParts(availableTimeRangesForSelectedDay, isSchedulerPage, currentDateSchedulerTime);
    }
  }

  /**
   * @deprecated
   */
  this.init = function() {/*backward compat func*/};

  (function init() {
    $(document).trigger('onEventsInit', _this);

    debug('init');
    _this.initDatetimepicker($('input[name=performerId]').val() || 0);

    if ($.isFunction($.fn.charCount)) {
      $(".js-text_limit").charCount({
        "css": "counter note-fieldheader"
      });
    }

    $(document).trigger('onEventsInited', _this);

    debug('init finished');

  }());

  function debug(msg) {
    if (isDebug === true) {
      const now = new Date().toLocaleTimeString();
      console.log(`[SCHEDULED SHOWS][${now}]`, msg);
    }
  }
}

$.fn.renderDayParts = function (availableRanges, isSchedulerPage, currentDateSchedulerTime = []) {
  if (!availableRanges || availableRanges.length < 1) {
    return;
  }
  const availableRangesSort = availableRanges.sort((a, b) => a.startTime[0] - b.startTime[0]);

  const dayParts = {
    morning: [],
    afternoon: [],
    evening: [],
    night: []
  }

  for (const availableRange of availableRangesSort) {
    const startHour = availableRange.startTime[0];
    const startMinute = availableRange.startTime[1];
    const endHour = availableRange.endTime[0];
    const endMinute = availableRange.endTime[1];
    let timeLockId = -1;

    if (isSchedulerPage) {

      for (const schedulerTime of currentDateSchedulerTime) {
        if (schedulerTime.startHour.hour === startHour) timeLockId = schedulerTime.id;
      }
    }

    const dataTime = {
      formatedInterval: getFormattedTime(startHour, startMinute) + " - " + getFormattedTime(endHour, endMinute),
      startHour: startHour,
      timeLockId: timeLockId
    };

    if (startHour >= 5 && startHour < 11) dayParts.morning.push(dataTime);
    if (startHour >= 11 && startHour < 17) dayParts.afternoon.push(dataTime);
    if (startHour >= 17 && startHour < 23) dayParts.evening.push(dataTime);
    if (startHour >= 0 && startHour < 5) dayParts.night.push(dataTime);
    if (startHour === 23) dayParts.night.unshift(dataTime);
  }

  this.find('.day-part').children().not('.time-title').remove();
  for (const key in dayParts) {
    for (const dataTime of dayParts[key]) {
      this.find('#part-'+key).append(
        isSchedulerPage
          ? '<li class="time js-time-add' + (dataTime.timeLockId !== -1 ? ' checked' : '') + '" data-hour="'+dataTime.startHour+'"' + (dataTime.timeLockId !== -1 ? ' data-id="'+dataTime.timeLockId+'"' : '') + '>' +
              ''+dataTime.formatedInterval +
            '</li>'
          : '<li class="time">'+dataTime.formatedInterval+'</li>'
      );
    }
  }

  function getFormattedTime(hour, minute) {
    if (hour === 24) hour = 0
    const formatTimeUnit = timeUnit => timeUnit < 10 ? '0' + timeUnit : timeUnit;

    return `${formatTimeUnit(hour)}:${formatTimeUnit(minute)}`;
  }
};



const $scheduler = $('.schedule-calendar .scheduler');

$scheduler.on("click", ".js-time-add", (e) => {toggleDateAvailability(e)});
$scheduler.on("click", ".js-part-add", (e) => {toggleDateAvailability(e)});

let lastMousedownTime = 0;
$scheduler.on("mousedown", "td[data-event='click'] a.ui-state-default", async (e) => {
  e.stopPropagation();
  e.preventDefault();
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - lastMousedownTime;

  if (timeDiff < 300) {
    await toggleDateAvailability(e);
    (function () {
      location.reload();
    })()
  } else {
    lastMousedownTime = currentTime;
  }
});

function toggleDateAvailability(e) {
  e.preventDefault();
  const dataEl = e.target;
  const $dataEl = $(dataEl);

  if ($dataEl.hasClass("inProgress")) return;
  $dataEl.addClass("inProgress");

  const id = $dataEl.data('id');
  let data;

  let initDate = $('input[name=datetime-scheduler]').val() || "";
  let initDay;
  let initMonth;
  let initYear;
  if (initDate === "") {
    $dataEl.removeClass('inProgress');
    return;
  }

  initDate.split('/').forEach((item, index) => {
    if (index === 0) initMonth = item;
    if (index === 1) initDay = item;
    if (index === 2) initYear = item;
  })

  if (typeof id !== 'number') {
    let day = $dataEl.data('date'),
      part = $dataEl.data('part'),
      hour = $dataEl.data('hour');

    if (!hour && hour !== 0) {
      let parentFilter = ".checked";
      let query;

      if (day) {
        query = "#time-picker-scheduler .js-time-add";
      } else if (part) {
        query = "#part-"+part+" .js-time-add";
      }

      let parents = $(query+":not(.checked)");
      if (parents.length >= 1) {
        parentFilter = ":not(.checked)";
      }

      $(query + parentFilter).trigger("click");
      $dataEl.removeClass("inProgress");
      return;
    }
    data = {
      cmd: 'add-timelock',
      dayOfMonth: initDay,
      month: initMonth,
      year: initYear,
      startHour: hour
    };
  } else {
    data = {
      cmd: 'remove-timelock',
      id: id
    }
  }

  if (typeof id == 'number' || data.day !=='' && data.startHour !=='') {
    $.ajax({
      url: '/plugins/shows/scheduler-controller.jsp',
      method: 'POST',
      cache: false,
      data: data,
      success: function (response) {
        if (response.code=='OK') {
          if (response.event){
            $dataEl.data("id", response.event.id).attr("data-id", response.event.id);
            $dataEl.addClass("checked");
          } else {
            $dataEl.data("id","").attr("data-id","");
            $dataEl.removeClass("checked");
          }
        }
        $dataEl.removeClass("inProgress");
      },
      error: () => {
        alert('error!');
      }
    }).always(()=>{
      $dataEl.removeClass("inprogress");
    });
  }
};