let timeout;

let isSelected = false;
let selectCells = true;

let $calendar = $("#calendar-events");

$("body").on("mouseup",function(e){
  isSelected = false;
});

$calendar.on("mousedown","td", function(e){
  isSelected = true;
  selectCells =$(this).hasClass("checked");
})
  .on("mousemove","td:not(.day):not(.hour)",function(e){
  if (isSelected ){
    let tdClass = $(this).hasClass("checked");
    if (selectCells && tdClass || !selectCells && !tdClass  ) $(this).trigger("click");
  }
})
  .on("click", "td", function(e) {
    e.preventDefault();
    const $t = $(this);
    if ($t.hasClass("inProgress")) return;
    $t.addClass("inProgress");
    const id = $t.data('id');
    const dataEl = $t.find('span');
    let data;

    if (typeof id !== 'number') {
      let day = dataEl.data('day'),
        startHour = dataEl.data('hour');

      if (day === '' || startHour === '') {
        let parentFilter = ".checked";
  //        $t.toggleClass("checked");
        let query = "span[data-day"+(day!=='' ? "="+day : "" )+"][data-hour"+(startHour!=='' ? "="+startHour : "" )+"]";
  //        let hours = $("table.calendar").find(query).parent(parentFilter).trigger("click");
        let hours = $("table.calendar").find(query);
        let parents = hours.parent(":not(.checked)");
        if (parents.length > 1) {
          parentFilter = ":not(.checked)";
        }
        hours.parent(parentFilter).trigger("click");
        $t.removeClass("inProgress");
        return;
      }
      data = {
        cmd: 'add-calendar',
        day: day,
        startHour: startHour
      };
    } else {
      data = {
        cmd: 'remove-calendar',
        id: id
      }
    }

    if (typeof id == 'number' || data.day !=='' && data.startHour !=='') {
      $.ajax({
        url: '/plugins/shows/calendar-controller.jsp',
        method: 'POST',
        cache: false,
        data: data,
        success: function (response) {
          if (response.code=='OK') {
  //            dataEl.removeClass("fa-check-square-o fa-square-o");
            if (response.event){
              $t.data("id",response.event.id).attr("data-id",response.event.id);
              $t.addClass("checked");
  //              dataEl.addClass("fa-check-square-o");
            } else {
              $t.data("id","").attr("data-id","");
              $t.removeClass("checked");
  //              dataEl.addClass("fa-square-o");
            }
          }
          $t.removeClass("inProgress");
        },
        error: () => {
          alert('error!');
        }
      }).always(()=>{
        $t.removeClass("inprogress");
      });
    } else if (typeof id !== 'number' && ( data.day =='' || data.startHour !='')) {}

    clearTimeout(timeout);
    timeout = setTimeout(() => window.location.reload(), 2000);
});
/*
$('.calendar tr').not(':eq(0)').find('td').not(':eq(0)').on('click', function () {

  const id = $(this).data('id');
  const dataEl = $(this).find('span');

  let data;
  if (typeof id !== 'number') {
    data = {
      cmd: 'add-calendar',
      day: dataEl.data('day'),
      startHour: dataEl.data('hour')
    };
  } else {
    data = {
      cmd: 'remove-calendar',
      id: id
    }
  }

  if (dataEl) {
    $.ajax({
      url: '/plugins/shows/calendar-controller.jsp',
      method: 'POST',
      cache: false,
      data: data,
      success: () => {
//          $(this).unbind('click');
//          location.reload();
      },
      error: () => {
        alert('error!');
      }
    });
  } else {
    console.log('no data element');
  }

  // $(this).css('background-color', 'lime');

});
*/